@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .red-polygon {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        background-color: red;
    }
}

html,
body,
:root {
    height: 100%;
    overflow: hidden;
}

[type='text']:focus {
    box-shadow: none;
}

@layer base {
    h1 {
        @apply text-6xl font-semibold;
    }

    h2 {
        @apply text-4xl font-semibold;
    }

    h3 {
        @apply text-2xl font-semibold;
    }

    h4 {
        @apply text-xl;
    }

    a {
        @apply text-inventi-purple;
    }

    p {
        @apply text-inventi-paragraph;
    }
}
